import React, { useContext, useState } from 'react'
import { linkResolver } from '../utils/linkResolver'
import { Link } from '@superrb/gatsby-addons/components'
import { NavContext } from '@superrb/gatsby-addons/context'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'

const NavigationItem = ({ item, children }) => {
  const { closeNav } = useContext(NavContext)
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile(true)

  if (!item.link) {
    return null
  }

  const itemContent = (
    <>
      <span className="nav_link-text">{item.label}</span>
      <span className="nav_link-text_copy" aria-hidden="true">
        {item.label}
      </span>
    </>
  )

  const toggleOpen = () => {
    setOpen(open => !open)
  }

  return (
    <li
      className={`main-nav__item ${
        children ? 'main-nav__item--has-children' : ''
      }`}
    >
      {children ? (
        <button
          className="main-nav__link"
          onClick={toggleOpen}
          aria-expanded={open}
        >
          {itemContent}
        </button>
      ) : (
        <Link
          className="main-nav__link"
          to={linkResolver(item.link)}
          onClick={closeNav}
        >
          {itemContent}
        </Link>
      )}

      {children}
    </li>
  )
}

export default NavigationItem
