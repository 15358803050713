import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MainFooter from '../types/main-footer'
import { SiteConfig } from '@superrb/gatsby-addons/types'
import Instagram from './icons/instagram'
import LinkedIn from './icons/linkedin'
import Twitter from './icons/twitter'
import Yeti from './icons/yeti'
import Fire from './icons/fire'
import Arrow from './icons/arrow'
import NavigationItem from './navigation-item'

const Footer = () => {
  const [leftNavigationItems, setLeftNavigationItems] = useState([])
  const [rightNavigationItems, setRightNavigationItems] = useState([])

  const data = useStaticQuery(graphql`
    query FooterQuery {
      footer: prismicMainfooter {
        ...MainFooter
      }
      config: prismicSiteConfig {
        ...SiteConfig
      }
    }
  `)

  useEffect(() => {
    setLeftNavigationItems(
      data?.footer?.data?.navigation_items?.slice(
        0,
        data.footer.data.navigation_items.length / 2,
      ),
    )
    setRightNavigationItems(
      data?.footer?.data?.navigation_items?.slice(
        data.footer.data.navigation_items.length / 2,
      ),
    )
  }, [data])

  /** @type {MainFooter} footer */
  const footer = data?.footer
  if (!footer) {
    return null
  }

  /** @type {SiteConfig} config */
  const config = data?.config
  if (!config) {
    return null
  }

  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__top">
          <h5 className="footer__title">
            {footer.data.title}
            <Fire />
          </h5>
          <a href={`mailto:${config.data.email_address}`}>
            <h5 className="footer__email">{config.data.email_address}</h5>
          </a>
          <div className="footer__socials">
            <span className="footer__socials-instagram">
              <a
                href={`https://instagram.com/${config.data.instagram_username}`}
              >
                <Instagram />
              </a>
            </span>
            <span className="footer__socials-linkedin">
              <a href={`https://linkedin.com/${config.data.linkedin_username}`}>
                <LinkedIn />
              </a>
            </span>
            <span className="footer__socials-twitter">
              <a href={`https://twitter.com/${config.data.twitter_username}`}>
                <Twitter />
              </a>
            </span>
          </div>
        </div>
        <div className="footer__links">
          <ul className="footer__links-left">
            {leftNavigationItems.map((item, index) => (
              <NavigationItem item={item} key={index} />
            ))}
          </ul>
          <ul className="footer__links-right">
            {rightNavigationItems.map((item, index) => (
              <NavigationItem item={item} key={index} />
            ))}
          </ul>
        </div>
        <div className="footer__bottom">
          <Yeti />
          <p>©{new Date().getFullYear()} Doing Things. All Rights Reserved. </p>
          <p>
            Website by{' '}
            <a
              href="https://www.superrb.com"
              rel="nofollow noopener noreferrer"
              target="_blank"
              title="Website by Superrb"
              className="footer__credits"
            >
              Superrb
            </a>
          </p>
        </div>
        <div className="footer__back-to-top">
          <a href="#top">
            <>
              <span className="nav_link-text">BACK TO TOP</span>
              <span className="nav_link-text_copy" aria-hidden="true">
                BACK TO TOP
              </span>
            </>
          </a>
          <Arrow />
        </div>
      </div>
    </footer>
  )
}

export default Footer
