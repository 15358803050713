import React from 'react'

const LinkedIn = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.9196 0.599609H6.43961C3.23961 0.599609 0.599609 3.23961 0.599609 6.43961V22.9996C0.599609 26.1996 3.23961 28.8396 6.43961 28.8396H22.9996C26.1996 28.8396 28.8396 26.1996 28.8396 22.9996V6.43961C28.8396 3.23961 26.1996 0.599609 22.9996 0.599609H22.9196ZM9.95961 22.9196H6.43961V9.95961H9.95961V22.9996V22.9196ZM8.19961 8.51961C7.64796 8.51961 7.1189 8.30047 6.72883 7.91039C6.33875 7.52032 6.11961 6.99126 6.11961 6.43961C6.11961 5.88796 6.33875 5.3589 6.72883 4.96883C7.1189 4.57875 7.64796 4.35961 8.19961 4.35961C9.39961 4.35961 10.2796 5.31961 10.2796 6.43961C10.2796 7.55961 9.39961 8.51961 8.19961 8.51961ZM24.0396 22.9196H20.5996V16.3596C20.5996 12.3596 15.9596 12.6796 15.9596 16.3596V22.9196H12.4396V9.95961H15.7996V12.0396C17.3996 8.99961 23.9596 8.83961 23.9596 14.9996V22.9196H24.0396Z" />
  </svg>
)

export default LinkedIn
