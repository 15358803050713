const scrollToHash = hash => {
  const element = document.getElementById(hash)

  if (!element) {
    return true
  }

  let top = window.scrollY + element.getBoundingClientRect().top + 5
  if (top < window.scrollY) {
    top = top - 100
  }
  window.scrollTo({ top, behavior: 'auto' })
}

export default scrollToHash
