import React, { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from './header'
import Footer from './footer'
import { SkipTo } from './skip-to'
import useAnchorLinks from '../hooks/use-anchor-links'
import scrollToHash from '../utils/scroll-to-hash'

export const Layout = ({ children }) => {
  useAnchorLinks()

  useEffect(() => {
    if (window.location.hash) {
      scrollToHash(window.location.hash.slice(1))
    }
  }, [])

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ben1zko.css" />
      </Helmet>
      <SkipTo />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}
