import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MainHeader from '../types/main-header'
import { SiteConfig } from '@superrb/gatsby-addons/types'
import Logo from './icons/logo'
import MenuToggle from './navigation/mobile-menu-toggle'
import { NavContext } from '@superrb/gatsby-addons/context'
import { useEventListener, useIsMobile } from '@superrb/gatsby-addons/hooks'
import { AnimatedButton } from './buttons/animated-button'
import { linkResolver } from '../utils/linkResolver'
import Instagram from './icons/instagram'
import LinkedIn from './icons/linkedin'
import Twitter from './icons/twitter'
import NavigationItem from './navigation-item'
import { Link } from '@superrb/gatsby-addons/components'

const Header = () => {
  const data = useStaticQuery(graphql`
    query MainHeaderQuery {
      header: prismicMainheader {
        ...MainHeader
      }
      config: prismicSiteConfig {
        ...SiteConfig
      }
    }
  `)

  const isMobile = useIsMobile()
  const { navOpen } = useContext(NavContext)
  const headerElement = useRef(null)
  let lastScroll = 0

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      if (isMobile) {
        if (window.scrollY < 25) {
          document.body.classList.add('at-top')
        } else {
          document.body.classList.remove('at-top')
        }
      }

      if (window.scrollY > 50) {
        document.body.classList.add('header-stuck')
      } else {
        document.body.classList.remove('header-stuck')
      }
    })
  }, [isMobile])

  useEventListener(
    'scroll',
    handleScroll,
    { passive: true },
    typeof window !== 'undefined' ? window : undefined,
  )

  /** @type {MainHeader} header */
  const header = data?.header
  if (!header) {
    return null
  }

  /** @type {SiteConfig} config */
  const config = data?.config
  if (!config) {
    return null
  }

  return (
    <header
      className={`main-header ${navOpen ? 'open' : ''}`}
      ref={headerElement}
    >
      <div className="main-header__container">
        <Link to="/#" className="main-header__logo">
          <Logo />
        </Link>

        <nav
          className="main-nav main-header__nav"
          aria-hidden={isMobile && !navOpen}
        >
          <ul id="main-nav" className="main-nav__list">
            {header.data.navigation_items.map((item, index) => (
              <NavigationItem item={item} key={index}>
                {(item.label === 'License' || item.label === 'Shop') && (
                  <ul className="main-nav__subnav">
                    {header.data.shop_navigation_items.map((item, index) => (
                      <li className="main-nav__subnav-item" key={index}>
                        <Link to={item.link}>{item.label}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </NavigationItem>
            ))}
            {isMobile && (
              <NavigationItem
                item={{
                  label: header.data.button_label,
                  link: header.data.button_url,
                }}
                key="contact"
              />
            )}
          </ul>
          {header.data.button_label && header.data.button_url && (
            <AnimatedButton
              link={linkResolver(header.data.button_url)}
              text={header.data.button_label}
              classes="main-header__button"
            />
          )}
          <div className="main-header__footer">
            <span className="main-header__footer-socials">
              {config.data.instagram_username && (
                <a
                  href={`https://instagram.com/${config.data.instagram_username}`}
                >
                  <Instagram />
                </a>
              )}
            </span>
            {config.data.linkedin_username && (
              <span className="main-header__footer-socials">
                <a
                  href={`https://linkedin.com/${config.data.linkedin_username}`}
                >
                  <LinkedIn />
                </a>
              </span>
            )}
            {config.data.twitter_username && (
              <span className="main-header__footer-socials">
                <a href={`https://twitter.com/${config.data.twitter_username}`}>
                  <Twitter />
                </a>
              </span>
            )}
          </div>
        </nav>
        {isMobile && (
          <AnimatedButton
            link={linkResolver(header.data.button_url)}
            text={
              isMobile
                ? header.data.button_label.split(' ')[0]
                : header.data.button_label
            }
            classes="main-header__contact"
          />
        )}
        <MenuToggle className="main-header__toggle" label="Open Nav" />
      </div>
    </header>
  )
}

export default Header
