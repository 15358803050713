import React from 'react'

const Twitter = () => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_0_2651)">
      <path d="M26.8108 4.9283C25.8138 5.35154 24.7514 5.63206 23.6438 5.76828C24.7833 5.10991 25.6529 4.07532 26.0618 2.82829C24.9994 3.44127 23.8264 3.87424 22.5764 4.11586C21.5676 3.0764 20.1299 2.43262 18.5614 2.43262C15.5184 2.43262 13.0686 4.82289 13.0686 7.75315C13.0686 8.17477 13.1054 8.5802 13.196 8.96612C8.62638 8.75045 4.58298 6.63098 1.8667 3.40235C1.39248 4.19857 1.11432 5.10992 1.11432 6.091C1.11432 7.93316 2.09459 9.56614 3.55578 10.5115C2.67271 10.4953 1.80638 10.2472 1.07243 9.85643C1.07243 9.87262 1.07243 9.89368 1.07243 9.91475C1.07243 12.4996 2.97767 14.6467 5.47611 15.1413C5.0287 15.2596 4.54108 15.3164 4.03503 15.3164C3.68314 15.3164 3.32789 15.2969 2.99443 15.2256C3.70659 17.3321 5.72749 18.8807 8.13038 18.931C6.26032 20.3467 3.88589 21.1996 1.3154 21.1996C0.864649 21.1996 0.432324 21.1802 0 21.1267C2.43475 22.6461 5.32027 23.5137 8.432 23.5137C18.5464 23.5137 24.0761 15.4056 24.0761 8.37746C24.0761 8.14233 24.0677 7.91531 24.056 7.68994C25.1469 6.94073 26.0635 6.00506 26.8108 4.9283Z" />
    </g>
    <defs>
      <clipPath id="clip0_0_2651">
        <rect width="26.8108" height="25.9459" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Twitter
