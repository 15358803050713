
import React from 'react'

export const SkipTo = () => {

  return (
  <div className="skip-to">
    <a href="#content">Skip to Content</a>
    <a href="#nav">Skip to Navigation</a>
  </div>
  )
}


