module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"doingthingsmedia","accessToken":"MC5ZZFJsSnhJQUFDOEFxS3RS.ZjNQ77-977-977-977-9Vu-_ve-_vQ9977-977-9SO-_ve-_vXfvv73vv73vv70i77-9FhUxC23vv70k77-9bw"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#E1FA00","theme_color":"#E1FA00","display":"minimal-ui","icon":"/opt/build/repo/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e77f7c751979a7e0a34e8a36887f8986"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
