import React from 'react'
import Plane from '../icons/plane'

const icons = {
  plane: <Plane />,
}

export const AnimatedButton = ({
  link,
  text,
  icon,
  color = 'black',
  classes = '',
  ...props
}) => {
  if (!text) return null

  if (icon) {
    classes += `button--icon button--icon-${icon}`
  }

  const inner = (
    <>
      <div className="button__inner">
        <span data-text={text}>{text}</span>
      </div>
      {icon && (
        <div className={`button__icon button__icon--${icon}`}>
          {icons[icon]}
        </div>
      )}
    </>
  )

  if (link) {
    return (
      <a
        className={`button ${color == 'black' ? 'black' : 'green'} ${classes}`}
        href={link}
        {...props}
      >
        {inner}
      </a>
    )
  }

  return (
    <button
      className={`button ${color == 'black' ? 'black' : 'green'} ${classes}`}
      {...props}
    >
      {inner}
    </button>
  )
}
