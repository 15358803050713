import React from 'react'

const Plane = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 3H33V0H30V3ZM27 3H30V0H27V3ZM24 6H27V3H24V6ZM21 6H24V3H21V6ZM18 9H21V6H18V9ZM15 9H18V6H15V9ZM12 12H15V9H12V12ZM9 12H12V9H9V12ZM6 15H9V12H6V15ZM3 15H6V12H3V15ZM30 6H33V3H30V6ZM30 9H33V6H30V9ZM27 12H30V9H27V12ZM27 15H30V12H27V15ZM24 18H27V15H24V18ZM24 21H27V18H24V21ZM21 24H24V21H21V24ZM21 27H24V24H21V27ZM18 30H21V27H18V30ZM18 33H21V30H18V33ZM15 30H18V27H15V30ZM15 27H18V24H15V27ZM12 24H15V21H12V24ZM12 21H15V18H12V21ZM15 18H18V15H15V18ZM18 15H21V12H18V15ZM21 12H24V9H21V12ZM24 9H27V6H24V9ZM9 21H12V18H9V21ZM6 21H9V18H6V21ZM3 18H6V15H3V18ZM0 18H3V15H0V18Z"
      fill="#E1FA00"
    />
  </svg>
)

export default Plane
