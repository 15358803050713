/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
const homepageSections = [
  'homepagebrandpartnerssection',
  'homepagecasestudiessection',
  'homepagecontactformsection',
  'homepagecontentsection',
  'homepagecontentslidersection',
  'homepagefeaturedbrandssection',
  'homepagehandsection',
  'homepageheadersection',
  'homepageoriginalvideosection',
  'homepagelicensingsection',
  'homepagewhatwedosection',
  'homepagepresssection',
  'homepageproductssection',
  'homepagestatssection',
  'homepageifyouknowsection',
]

exports.linkResolver = doc => {
  if (doc.link_type === 'Web') {
    return doc.url
  }

  if (homepageSections.includes(doc.type)) {
    return `/#${doc.uid}`
  }

  switch (doc.type) {
    case 'legalpage':
      return `/legal/${doc.uid}`

    default:
      return '/'
  }
}
