import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

import { repositoryConfigs } from './src/utils/prismicPreviews'

import './src/stylesheets/main.sass'
import { Layout } from './src/components/layout'
import scrollToHash from './src/utils/scroll-to-hash'
import { ContextWrapper } from '@superrb/gatsby-addons/components'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <ContextWrapper>
        <Layout>{element}</Layout>
      </ContextWrapper>
    </PrismicPreviewProvider>
  </PrismicProvider>
)

export const onRouteUpdate = ({ location: {pathname} }) => {
  const hash = location.hash.slice(1)

  if (hash) {
    scrollToHash(hash)
  }

  document.body.setAttribute(
    'class',
    `page ${[
      ...pathname
        .split('/')
        .filter((x) => !!x)
        .map((slug, index) => `page--${slug} ${(index === 1 ? 'page--child' : '')}`),
    ].join(' ')}`,
  )
}
