import React, { useCallback, useContext } from 'react'
import { NavContext } from '@superrb/gatsby-addons/context'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'

const MenuToggle = ({ label, className, ...props }) => {
  const { navOpen, toggleNav } = useContext(NavContext)
  const isMobile = useIsMobile()

  const screenreaderText = navOpen ? 'Close Nav' : 'Open Nav'

  return (
    <button
      className={`menu-toggle ${className}`}
      onClick={() => {
        toggleNav()
      }}
      aria-expanded={navOpen}
      aria-controls="main-nav"
      aria-hidden={!isMobile}
      {...props}
    >
      <span className="menu-line"></span>
      <span className="screenreader-text">{screenreaderText}</span>
    </button>
  )
}

export default MenuToggle
